


































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetBairros,
  dispatchUpdateBairro,
} from '@/store/bairro/actions';
import { consultarBairro } from '@/store/bairro/getters';
import { IBairroUpdate } from '@/interfaces/bairro';

@Component
export default class AlterarBairro extends Vue {
  public valid = true;
  public nome: string = '';

  public async mounted() {
    await dispatchGetBairros(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.bairro) {
      this.nome = this.bairro.nome;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const bairroAtualizado: IBairroUpdate = {
        nome: this.nome,
      };

      const atualizou = await dispatchUpdateBairro(this.$store, {
        id: this.bairro!.id,
        bairro: bairroAtualizado,
      });
      if (atualizou) {
        this.$router.push('/main/bairros/listar');
      }
    }
  }

  get bairro() {
    return consultarBairro(this.$store)(+this.$router.currentRoute.params.id);
  }
}
